<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>业绩目标分析</a-breadcrumb-item>
            <a-breadcrumb-item>校区业绩分析</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-body students">
            <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
                <a-tab-pane key="1">
                <span slot="tab">校区业绩分析</span>
                <campus v-if="activeKey==='1'"/>
                </a-tab-pane>
                <a-tab-pane key="2">
                <span slot="tab">员工业绩分析</span>
                <employee v-if="activeKey==='2'"/>
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>

<script>
import campus from './campus'
import employee from './employee'
    export default {
        name:'achievement',
        data() {
            return {
                activeKey:'1'
            }
        },
        components: {
            campus,
            employee
        },
          methods: {
            changeTab(key) {
            this.activeKey = key
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>